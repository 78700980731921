import { ConfigState } from "../../../../store/config/types";
import { replaceWithSecureURL } from "../../../../utils/FileProcessingClientWrapped";
import { ignoreToCacheTheseParamsOnOurNativePlayers } from "../../../../utils/helpers";
import { extractOrgIdFromUrl } from "../AppViewerContainer/useAppInstanceFiles";

export const handleSecureMediaUrl = (
  url: string,
  opts: {
    secureMediaServiceURL?: string;
    secureMediaPolicy?: string;
    orgId?: string;
    config: ConfigState;
  }
): string => {
  const { orgId, secureMediaServiceURL, secureMediaPolicy, config } = opts;

  if (url && secureMediaServiceURL) {
    const extractedOrgId = extractOrgIdFromUrl(url);
    if (extractedOrgId === orgId) {
      console.log(extractedOrgId, config, url, secureMediaServiceURL);
      return `${replaceWithSecureURL(
        config,
        url,
        secureMediaServiceURL
      )}${ignoreToCacheTheseParamsOnOurNativePlayers(secureMediaPolicy)}`;
    }
  }
  return url;
};
