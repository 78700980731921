import { FilesState, PlayerFile } from "../../../../store/files/types";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { App } from "../../../../store/apps/types";
import isEqual from "lodash/isEqual";
import { useMemo, useRef } from "react";
import { ConfigState } from "../../../../store/config/types";
import { replaceWithSecureURL } from "../../../../utils/FileProcessingClientWrapped";
import { ignoreToCacheTheseParamsOnOurNativePlayers } from "../../../../utils/helpers";

// Temporary transform for files to expected shape by files
// Prevents breakage of apps which rely on the current player
const transformToAppFileStructure = (
  files: Array<PlayerFile>
): { nodes: Array<PlayerFile> } => {
  return { nodes: files || [] };
};

export interface AppInstanceFiles {
  nodes: PlayerFile[];
}

export function extractOrgIdFromUrl(url: string): string | null {
  try {
    const { pathname } = new URL(url);
    const segments = pathname.split("/").filter(Boolean);
    return segments.length > 0 ? segments[0] : null;
  } catch {
    return null;
  }
}

/**
 * Hook that gets you files that are used in an app instance.
 * Takes care of returning same object reference if files haven't changed.
 */
export const useAppInstanceFiles = (
  appInstanceId: string,
  isPreview: boolean
): AppInstanceFiles => {
  const prevFiles = useRef<AppInstanceFiles>();

  const app = useSelector<PlayerState, App | undefined>((state) => {
    if (!isPreview) {
      return state.apps.byId[appInstanceId];
    }
    return undefined;
  });

  const config = useSelector<PlayerState, ConfigState>((state) => state.config);

  const orgId = useSelector<PlayerState, string>((state) => {
    return state.screen.orgId || state.organization?.id;
  });

  const files = useSelector<PlayerState, FilesState>((state) => {
    return state.files;
  });

  const secureMediaServiceURL = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaServiceUrl
  );

  const secureMediaPolicy = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaPolicy
  );

  const appInstanceFiles = useMemo(() => {
    const fileEntities = app?.fileIds.map((id) => files.byId[id]) || [];

    const newFileEntities = fileEntities.map((file) => {
      if (file.source && secureMediaServiceURL) {
        const extractedOrgId = extractOrgIdFromUrl(file.source);
        if (extractedOrgId === orgId) {
          const newSecureURLWithPolicy = `${replaceWithSecureURL(
            config,
            file.source,
            secureMediaServiceURL
          )}${ignoreToCacheTheseParamsOnOurNativePlayers(secureMediaPolicy)}`;
          return {
            ...file,
            source: newSecureURLWithPolicy,
          };
        }
      }
      return file;
    });

    return transformToAppFileStructure(newFileEntities);
  }, [files, app, config, secureMediaPolicy, secureMediaServiceURL, orgId]);

  // having deep equality comparison here may have rendering performance repercussions in case of large file sets
  if (!isEqual(prevFiles.current, appInstanceFiles)) {
    prevFiles.current = appInstanceFiles;
    return appInstanceFiles;
  } else if (prevFiles.current === undefined) {
    prevFiles.current = { nodes: [] };
    return prevFiles.current;
  } else {
    return prevFiles.current;
  }
};
