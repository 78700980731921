/**
 * The purpose of this file:
 * Provide methods to get mock player entities to use in unit tests.
 *
 * Each function must accept an optional partial object of a target type. This will result in better readability of unit
 * tests, since the caller only provides object fields that matter for a particular unit test, default values are left
 * out of the tests' scope.
 */

import {
  AudioFile,
  DocumentFile,
  ImageFile,
  VideoFile,
} from "../../store/files/types";
import { genUuid } from "@screencloud/uuid";
import { App } from "../../store/apps/types";
import {
  GqlContentListItem,
  GqlScreenDeviceJson,
  GqlZoneItemTransition,
  TransitionType,
} from "../../store/graphqlTypes";
import { Link } from "../../store/links/types";
import { Channel } from "../../store/channels/types";
import { Layout } from "../../store/layouts/types";
import { Space } from "../../store/spaces/types";
import {
  ContentItemApp,
  ContentItemFile,
  ContentItemLeaf,
  ContentItemLink,
  ContentItemPlaylist,
  ContentItemSite,
  ContentList,
  ParentInfo,
} from "../../store/contentLists/types";
import {
  Timeline,
  TimelineItem,
  TimelineItemVoid,
} from "../../store/timelines/types";
import {
  BreakpointWithItems,
  TimelinePlaybackState,
} from "../../store/playback/types";
import { ScreenState } from "../../store/screen/types";
import { ContentViewAppItem } from "../../types/content";
import { Playlist } from "../../store/playlists/types";
import { TimeOptions } from "../../utils/timeManager";
import {
  ScheduleRules,
  WithAvailabilityRules,
} from "../../utils/scheduleFilter";
import { ConfigState } from "../../store/config/types";
import { ElementSize } from "../../utils/helpers";
import { Site } from "../../store/sites/types";
import { OrganizationState } from "../../store/organization/types";
import { Theme } from "../../store/themes/types";
import { PlaylistContentItem } from "../../providers/MenuProvider/types";
import { STUDIO_VARIABLES } from "../../utils/useGetStudioVariables";
import { QRCodeConfig } from "../../types/qrcode";
import { FullscreenItemReport } from "../../modules/viewers/containers/ChannelViewer/FullscreenContentViewer/hooks/useFullscreenContent";
import { SCREEN_TIMEZONE_OVERRIDE } from "../data-input-mocks/currentScreen.fixture";

export function mockAudioFile(input?: Partial<AudioFile>): AudioFile {
  return {
    type: "audio",
    urlKey: "/fake/url/key",
    source: "http://secure-media.com/fake/original/key",
    availableAt: null,
    expireAt: null,
    height: undefined,
    id: genUuid(),
    name: "fake file name",
    size: 100500,
    width: undefined,
    orgId: genUuid(),
    mimetype: "audio/mpeg",
    ...input,
  };
}

export function mockVideoFile(input?: Partial<VideoFile>): VideoFile {
  return {
    type: "video",
    urlKey: "/fake/url/key",
    source: "http://secure-media.com/fake/original/key",
    availableAt: null,
    expireAt: null,
    height: undefined,
    id: genUuid(),
    name: "fake file name",
    size: 100500,
    width: undefined,
    orgId: genUuid(),
    mimetype: "video/mp4",
    ...input,
  };
}

export function mockImageFile(input?: Partial<ImageFile>): ImageFile {
  return {
    type: "image",
    urlKey: "/fake/url/key",
    availableAt: null,
    expireAt: null,
    height: undefined,
    id: genUuid(),
    name: "fake file name",
    size: 100500,
    width: undefined,
    orgId: genUuid(),
    mimetype: "image/jpeg",
    source: "http://secure-media.com/fake/original/key",
    ...input,
  };
}

export function mockDocumentFile(input?: Partial<DocumentFile>): DocumentFile {
  return {
    type: "document",
    images: [mockImageFile()],
    availableAt: null,
    expireAt: null,
    height: undefined,
    id: genUuid(),
    name: "fake file name",
    size: 100500,
    width: undefined,
    orgId: genUuid(),
    source: "http://secure-media.com/fake/original/key",
    ...input,
  };
}

export function mockTimeOptions(input?: Partial<TimeOptions>): TimeOptions {
  return {
    timeOffset: 0,
    timelineControlOffset: 0,
    timezoneOverride: "Europe/London",
    ...input,
  };
}

export function mockScheduleRule(
  input?: Partial<ScheduleRules>
): ScheduleRules {
  return {
    ...input,
  };
}

export function mockWithAvailabilityRules(
  input?: Partial<WithAvailabilityRules>
): WithAvailabilityRules {
  return {
    availableAt: null,
    expireAt: null,
    rules: [],
    ...input,
  };
}

export function mockApp(input?: Partial<App>): App {
  return {
    appInstallId: genUuid(),
    config: {},
    fileIds: [],
    id: genUuid(),
    name: "app name",
    version: "1.0.0",
    viewerUrl: "http://example.com",
    slug: null,
    ...input,
  };
}

export function mockScreenDeviceJson(
  input?: Partial<GqlScreenDeviceJson>
): GqlScreenDeviceJson {
  return {
    id: genUuid(),
    settings: {
      name: "screen",
      updatedAt: new Date().getTime(),
      orientation: "landscape",
    },
    player_width: 1000,
    player_height: 800,
    releaseVersion: "1.0.0",
    deviceSystemTimezone: "Asia/Bangkok",
    screenId: genUuid(),
    connection: "online",
    deviceModel: "chromecast",
    connected_at: new Date().getTime(),
    devicePlatform: "chome os",
    disconnected_at: new Date().getTime(),
    ...input,
  };
}

export function mockChannel(input?: Partial<Channel>): Channel {
  return {
    name: "channel name",
    height: 800,
    id: genUuid(),
    layoutId: genUuid(),
    width: 1000,
    isHiddenZoneActive: false,
    ...input,
  };
}

export function mockAppInstance(input?: Partial<App>): App {
  return {
    id: genUuid(),
    appInstallId: genUuid(),
    config: {},
    fileIds: [],
    name: "fake name",
    version: "1.1.1",
    viewerUrl: "url",
    slug: "youtube-app",
    ...input,
  };
}

export function mockLink(input?: Partial<Link>): Link {
  return {
    id: genUuid(),
    name: "fake link name",
    url: "fake url",
    ...input,
  };
}

export function mockFile(input?: Partial<ImageFile>): ImageFile {
  return {
    id: genUuid(),
    availableAt: null,
    expireAt: null,
    height: undefined,
    name: "super file",
    size: 100500,
    width: undefined,
    type: "image",
    orgId: genUuid(),
    urlKey: "fake url key",
    mimetype: "image/jpg",
    source: "http://secure-media.com/fake/original/key",
    ...input,
  };
}

export function mockLayout(input?: Partial<Layout>): Layout {
  return {
    id: genUuid(),
    height: 100,
    width: 100,
    css: "fake css",
    isFlexible: false,
    isScalable: false,
    name: "fake layout name",
    zones: [],
    ...input,
  };
}

export function mockSpace(input?: Partial<Space>): Space {
  return {
    id: genUuid(),
    name: "fake space name",
    ...input,
  };
}

export function mockContentList(input?: Partial<ContentList>): ContentList {
  return {
    id: genUuid(),
    publishedAt: new Date("1990-01-01T00:00").toISOString(),
    items: [
      {
        id: genUuid(),
        type: "file",
        durationMs: 100500,
        sizeType: "fit",
        preloadDurationMs: 100,
        listId: genUuid(),
        parent: {
          listId: genUuid(),
          type: "playlist",
          id: genUuid(),
        },
        rules: undefined,
        availableAt: null,
        expireAt: null,
      },
    ],
    ...input,
  };
}

export function mockContentItemPlaylist(
  input?: Partial<ContentItemPlaylist>
): ContentItemPlaylist {
  return {
    defaultDurations: undefined,
    defaultSizeTypes: undefined,
    listId: genUuid(),
    rules: undefined,
    availableAt: null,
    expireAt: null,
    type: "playlist",
    id: genUuid(),
    ...input,
  };
}

export function mockTimeline(input?: Partial<Timeline>): Timeline {
  return {
    id: genUuid(),
    items: [],
    ...input,
  };
}

export function mockTimelineItemVoid(
  input?: Partial<TimelineItemVoid>
): TimelineItemVoid {
  return {
    type: "void",
    breakpointId: 0,
    startTimestampMs: 100500,
    isInfinite: false,
    fullDurationMs: 234237689,
    showDurationMs: 234237689,
    ...input,
  };
}

export function mockContentItemFile(
  input?: Partial<ContentItemFile>
): ContentItemFile {
  return {
    type: "file",
    id: genUuid(),
    listId: genUuid(),
    durationMs: 5000,
    sizeType: "fit",
    preloadDurationMs: undefined,
    rules: undefined,
    availableAt: null,
    expireAt: null,
    ...input,
  };
}

export function mockContentItemApp(
  input?: Partial<ContentItemApp>
): ContentItemApp {
  return {
    type: "app",
    id: genUuid(),
    listId: genUuid(),
    durationMs: 5000,
    preloadDurationMs: undefined,
    rules: undefined,
    sizeType: "fit",
    availableAt: null,
    expireAt: null,
    ...input,
  };
}

export function mockContentItemLink(
  input?: Partial<ContentItemLink>
): ContentItemLink {
  return {
    type: "link",
    id: genUuid(),
    listId: genUuid(),
    durationMs: 5000,
    preloadDurationMs: undefined,
    rules: undefined,
    sizeType: "fit",
    availableAt: null,
    expireAt: null,
    ...input,
  };
}

export function mockContentItemSite(
  input?: Partial<ContentItemSite>
): ContentItemSite {
  return {
    type: "site",
    id: genUuid(),
    listId: genUuid(),
    durationMs: 5000,
    preloadDurationMs: undefined,
    rules: undefined,
    sizeType: "fit",
    availableAt: null,
    expireAt: null,
    ...input,
  };
}

export function mockParentInfo(input?: Partial<ParentInfo>): ParentInfo {
  return {
    type: "playlist",
    id: genUuid(),
    listId: genUuid(),
    ...input,
  };
}

export function mockPlaybackState(
  input?: Partial<TimelinePlaybackState>
): TimelinePlaybackState {
  return {
    activeIndex: undefined,
    activeScreenTimeMs: undefined,
    nextIndex: undefined,
    isActiveItemTransitioningOut: false,
    hasPreloadingStarted: false,
    id: genUuid(),
    nextFragmentRequestTimestamp: undefined,
    ...input,
  };
}

export function mockPlaylist(input?: Partial<Playlist>): Playlist {
  return {
    id: genUuid(),
    name: "fakePlaylistName",
    ...input,
  };
}

export function mockScreen(input?: Partial<ScreenState>): ScreenState {
  const screenId = genUuid();
  return {
    id: screenId,
    orgId: "cbd6cc4c-a824-4229-8104-8aeba2422d9f",
    name: "vic gbook",
    deviceId: genUuid(),
    spaceId: genUuid(),
    operatingHours: undefined,
    isMuted: undefined,
    deviceInfo: {},
    status: "LIVE",
    isPreview: false,
    videoSource: "optimized",
    highResolutionImage: true,
    activeContentItem: { type: "app", id: "app id 1" } as ContentViewAppItem,
    screenData: undefined,
    playerWidth: 1680,
    playerHeight: 403,
    playerRelease: genUuid(),
    playerTimezone: "Asia/Saigon",
    timezoneOverride: SCREEN_TIMEZONE_OVERRIDE,
    devicePlatform: "browser",
    ...input,
  };
}

export function mockScreenDeviceMeta(
  input?: Partial<GqlScreenDeviceJson>
): GqlScreenDeviceJson {
  return {
    id: genUuid(),
    screenId: genUuid(),
    settings: {
      name: "vic gbook",
      updatedAt: 1584442840158,
      orientation: "landscape",
    },
    connection: "online",
    deviceModel: "Browser",
    connected_at: 1588248902.246566,
    player_width: 1680,
    player_height: 403,
    devicePlatform: "browser",
    releaseVersion: genUuid(),
    disconnected_at: 1588248308.779056,
    deviceSystemTimezone: "Asia/Saigon",
    overrideTimezone: "Europe/Kiev",
    ...input,
  };
}

export function mockConfigState(input?: Partial<ConfigState>): ConfigState {
  return {
    timeOffset: 0,
    graphqlEndpoint: "http://fake.endpoint.com",
    graphqlToken: "fake",
    orientation: "landscape",
    imgixDomain: "bloody.imgix.net",
    linkCloudServiceUrl: "http://fake",
    contentConfig: {
      id: "id",
      type: "screen",
      isPreview: false,
    },
    region: "us",
    mediaOrigin: "http://fake.endpoint.com",
    lokiEndpoint: "http://lokiendpoint",
    version: "",
    rumSampleRate: 100,
    secureMediaServiceUrl: "https://secure-media.test.com",
    ...input,
  };
}

export function mockGqlContentListItem(
  input?: Partial<GqlContentListItem>
): GqlContentListItem {
  return {
    content: {
      _ref: {
        id: genUuid(),
        type: "channel",
      },
    },
    list_id: genUuid(),
    ...input,
  };
}

export function mockElementSize(input?: Partial<ElementSize>): ElementSize {
  return {
    width: 100,
    height: 100,
    ...input,
  };
}

export function mockSite(input?: Partial<Site>): Site {
  return {
    id: genUuid(),
    type: "",
    url: "https://example.com",
    name: "Site name",
    config: {},
    fileByThumbnailId: null,
    ...input,
  };
}

export function mockOrganization(
  input?: Partial<OrganizationState>
): OrganizationState {
  return {
    id: genUuid(),
    featureFlags: [],
    brandChannelId: genUuid(),
    defaultThemeId: genUuid(),
    loggingUrlOverride: "",
    ...input,
  };
}

export function mockBreakpointWithItems(
  input?: Partial<BreakpointWithItems>
): BreakpointWithItems {
  return {
    id: 0,
    breakpointTimestamp: new Date().getTime(),
    items: [],
    totalDurationMs: 100500,
    ...input,
  };
}

function randomElementFrom<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function mockTimelineItem(input?: Partial<TimelineItem>): TimelineItem {
  const randomType = randomElementFrom<"file" | "app" | "link" | "site">([
    "file",
    "app",
    "link",
    "site",
  ]);
  const randomSizeType = randomElementFrom<"fill" | "fit">(["fill", "fit"]);
  return {
    startTimestampMs: new Date().getTime() + Math.random() * 10000,
    breakpointId: 0,
    id: genUuid(),
    type: randomType,
    sizeType: randomSizeType,
    preloadDurationMs: Math.random() * 15000,
    isInfinite: false,
    fullDurationMs: Math.random() * 20000,
    listId: genUuid(),
    showDurationMs: Math.random() * 100000000,
    ...input,
  };
}

export function mockMenuContentItemPlaylist(
  input?: Partial<PlaylistContentItem>
): PlaylistContentItem {
  return {
    type: "playlist",
    id: genUuid(),
    name: "playlist name",
    activeItems: [
      {
        zoneId: "root",
        contentId: "1",
        contentType: "link",
      },
    ],
    ...input,
  };
}

export function mockTheme(input?: Partial<Theme>): Theme {
  return {
    id: "8ea2d8cd-46be-4c3c-96b8-7745dfc0f80a",
    name: "Red Blue",
    primaryColor: {
      "100": "#fecce0",
      "200": "#fd99c1",
      "300": "#fd66a2",
      "400": "#fc3383",
      "500": "#fc0064",
      "600": "#c90050",
      "700": "#97003c",
      "800": "#640028",
      "900": "#320013",
    },
    secondaryColor: {
      "100": "#cef5fc",
      "200": "#9eebf9",
      "300": "#6ee2f6",
      "400": "#3ed8f3",
      "500": "#0ecff1",
      "600": "#0ba5c0",
      "700": "#087c90",
      "800": "#055260",
      "900": "#022930",
    },
    textOnPrimary: {
      "100": "#fbf9f9",
      "200": "#f7f4f4",
      "300": "#f3efef",
      "400": "#efeaea",
      "500": "#ece5e5",
      "600": "#bcb7b7",
      "700": "#8d8989",
      "800": "#5e5b5b",
      "900": "#2f2d2d",
    },
    textOnSecondary: {
      "100": "#cccccc",
      "200": "#999999",
      "300": "#666666",
      "400": "#333333",
      "500": "#000000",
      "600": "#000000",
      "700": "#000000",
      "800": "#000000",
      "900": "#000000",
    },
    bodyFont: {
      url: "https://example.com/font.ttf",
      family: "Varela Round",
    },
    headingFont: {
      url: "https://example.com/testfont.woff",
      family: "Rubik",
    },
    ...input,
  };
}

export const mockContentItemLeaf = (
  input?: Partial<ContentItemLeaf>
): ContentItemLeaf => {
  const randomType = randomElementFrom<"file" | "app" | "link" | "site">([
    "file",
    "app",
    "link",
    "site",
  ]);

  return {
    type: randomType,
    durationMs: Infinity,
    preloadDurationMs: 0,
    listId: "",
    rules: [],
    availableAt: null,
    id: genUuid(),
    expireAt: null,
    sizeType: "fit",
    ...input,
  };
};

export const mockQrCodeConfig = (
  input?: Partial<QRCodeConfig>
): QRCodeConfig => {
  return {
    payload: {
      target_url: "https://www.google.com",
      client_id: "studio",
      persistent: true,
      context: {
        screen_id: STUDIO_VARIABLES._SC_SCREEN_ID,
        secondary_tenant_id: STUDIO_VARIABLES._SC_SPACE_ID,
        source_id: STUDIO_VARIABLES._SC_LINK_ID,
        source_type: STUDIO_VARIABLES._SC_CONTENT_TYPE,
        tenant_id: STUDIO_VARIABLES._SC_ORG_ID,
      },
    },
    prop: {
      position: "top left",
    },
    ...input,
  };
};

export function mockTransition(
  input?: Partial<GqlZoneItemTransition>
): GqlZoneItemTransition {
  return {
    duration: 5000,
    type: TransitionType.Dissolve,
    ...input,
  };
}

export function mockFullscreenItemReport(
  input?: Partial<FullscreenItemReport>
): FullscreenItemReport {
  return {
    timelineId: genUuid(),
    item: mockTimelineItem(),
    indexInTimeline: 0,
    nextItemStartTimestamp: new Date().getTime(),
    ...input,
  };
}

export function mockSecureMediaPolicy() {
  return "Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9zZWN1cmUtbWVkaWEuZXUuc2NyZWVuY2xvdWQuY29tLzA2ODlmZTJjLTM1NjUtNDU4ZC1hZTI5LTM1MzVmZmEwMWVmYS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjkwMDAwMDM4fX19XX0_&Key-Pair-Id=K2G7EC7KMA4V5I&Signature=hhZiLXskwqxLna~-IDQIh0cJmU~qEVG~IPULpAlFfgIq8efX1Q7MyzFUwYt02wTnXCqvzLApZc2L9lrnGkScOqIBhb4MYHEpU24RW4vkWrcajUuDhrWvHK~J53scu88IUTTGoCw8ZCMk0uPB3677fw8ctJjkF-ZFggOjeWgNQKPGo0qpKK92Mnv0gOTOEu3g2-76Axz6fZehlnKlCVZxePVVZsTKM7nl5rNbHMo2R5QQwnxLnE9CaPYbMovQxv0Od2eX1sOqHuBhFIPRmjUXCjEGtFN1SkARSR94GG-bjnzfuV2mJip4XA9HQLRzmhoMnoWbrQKBr~4pSRhK1Met9Q__";
}
