import React, { ReactElement, useEffect } from "react";

import Lottie from "./LottieWrapper";
import animation from "./sc-preloader-animation.json";
import styles from "./Loading.module.css";
import { Logger } from "../../../../logger/logger";

const log = new Logger("Loading");

interface LoadingProps {
  timeoutMs?: number;
  message?: string;
}

export const Loading = ({
  timeoutMs = 60000, // Default timeout of 1 minute
  message = "Building your content",
}: LoadingProps): ReactElement => {
  useEffect(() => {
    // Log when loading starts
    log.info(`Loading component mounted with timeout: ${timeoutMs}ms`);

    // Set up reload timer
    const reloadTimer = setTimeout(() => {
      log.warn("Loading timeout reached, reloading application");
      window.location.reload();
    }, timeoutMs);

    // Clean up timer on unmount
    return () => {
      clearTimeout(reloadTimer);
      log.info("Loading component unmounted");
    };
  }, [timeoutMs]);

  return (
    <div data-testid="loading" className={styles.wrapper}>
      <div>
        <div>
          <Lottie
            options={{
              animationData: animation,
            }}
          />
        </div>
        <div className={styles.loaderTextContainer}>
          <p className={styles.loaderText}>{message}</p>
        </div>
      </div>
    </div>
  );
};
