import { FileProcessingClientInterface } from "../types/filesClient";
import { IFileUrlOptions } from "@screencloud/signage-files-client/build/classes/FileprocessingClient";
import {
  FileprocessingClient,
  SignedUrlClient,
} from "@screencloud/signage-files-client";
import { produceSecureUrl } from "@screencloud/studio-media-client";

export interface Config {
  imgixDomain: string;
  mediaOrigin: string;
  secureMediaServiceUrl: string | undefined;
}

/**
 * This class is for refactoring purpose. Signage-files-client will be deprecated and replaced by studio-media-client
 */
export class FileProcessingClientWrapped
  implements FileProcessingClientInterface {
  private wrappedClient: FileprocessingClient;
  private secureMediaServiceUrl: string | undefined;

  constructor(config: Config) {
    this.wrappedClient = new FileprocessingClient({
      imgixDomain: config.imgixDomain,
      mediaOrigin: config.mediaOrigin,
      signedUrlClient: new SignedUrlClient({}),
    });
    this.secureMediaServiceUrl = config.secureMediaServiceUrl;
  }

  public getImgixUrl(
    fileKey: string,
    options: IFileUrlOptions,
    secureMediaPolicy?: string
  ) {
    const baseUrl = this.wrappedClient.getImgixUrl(fileKey, options);

    let urlToUse = baseUrl;
    if (secureMediaPolicy) {
      urlToUse = produceSecureUrl({
        baseUrl,
        policy: secureMediaPolicy,
      });
    }

    if (options.size) {
      urlToUse = getResizedMedia(
        urlToUse,
        options.size.h,
        options.size.w,
        options.sizingType
      );
    }

    return replaceWithSecureURL(
      this.wrappedClient.config,
      urlToUse,
      this.secureMediaServiceUrl
    );
  }

  public getMediaUrlForKey(urlKey: string, secureMediaPolicy?: string) {
    const baseUrl = this.wrappedClient.getMediaUrlForKey(urlKey);

    let urlToUse = baseUrl;
    if (secureMediaPolicy) {
      urlToUse = produceSecureUrl({
        baseUrl,
        policy: secureMediaPolicy,
      });
    }

    return replaceWithSecureURL(
      this.wrappedClient.config,
      urlToUse,
      this.secureMediaServiceUrl
    );
  }
}

export const replaceWithSecureURL = (
  config: {
    imgixDomain: string;
    mediaOrigin: string;
  },
  targetUrl: string,
  secureMediaServiceUrl: string | undefined
) => {
  // Log warning if secure media service URL is not provided
  if (!secureMediaServiceUrl) {
    console.warn(
      "Secure media service URL is missing, this may pose security risks"
    );
    return targetUrl;
  }

  // Safely parse URL and handle invalid URLs gracefully
  let parsedUrl: URL;
  try {
    parsedUrl = new URL(targetUrl);
  } catch (e) {
    console.warn(`Invalid target URL: ${targetUrl}, using original URL`);
    return targetUrl;
  }

  const { imgixDomain, mediaOrigin } = config;
  // Filter out any undefined or empty values
  const validOrigins = [imgixDomain, mediaOrigin].filter(Boolean);
  const targetUrlLower = targetUrl.toLowerCase();

  // Try to match against valid domains
  const domainMatched = validOrigins.some((domain) => {
    const domainLower = domain.toLowerCase();
    return (
      targetUrlLower.startsWith(`http://${domainLower}`) ||
      targetUrlLower.startsWith(`https://${domainLower}`) ||
      targetUrlLower.startsWith(domainLower)
    );
  });

  try {
    // Always attempt to use secure URL
    const reg = new RegExp("^https?://[^#?/]+", "i");
    if (domainMatched) {
      return targetUrl.replace(reg, secureMediaServiceUrl);
    }

    // For non-matching domains, try to construct a secure URL
    const pathWithQuery =
      parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
    return `${secureMediaServiceUrl}${pathWithQuery}`;
  } catch (e) {
    // If URL transformation fails, log warning and return original URL
    console.warn("Failed to transform URL to secure URL:", e);
    return targetUrl;
  }
};

/**
 * returns resized media url
 */

export const getResizedMedia = (
  url: string,
  height: number,
  width: number,
  fit = "fit"
) => {
  let sizeType = "inside"; // default to inside is equal to clip in imgix
  if (fit === "fill") {
    // cover is equal to crop in imgix
    sizeType = "cover";
  }

  return url?.replace("originals", `${width}x${height}_${sizeType}`);
};
