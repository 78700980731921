import "./utils/importMandatoryPolyfills";
import "./wdyr";

import "reset-css"; // Before other imports. Removes all native browser styling.
import "whatwg-fetch"; // required until cypress supports fetch API. https://github.com/cypress-io/cypress/issues/95
import "./index.css";
import "core-js/stable/set-immediate"; // Polyfill for setimmediate

import { AnyAction, Store } from "redux";
import { ClientContext, GraphQLClient } from "graphql-hooks";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { createFileProcessingClient, createGraphQLClient } from "./setup";
import { ClientApiProvider } from "./ClientApiContext";
import { ConfigState, DeviceConfig } from "./store/config/types";
import { ConfigurationManager } from "./configurationManager";
import { LiveUpdatesContainer } from "./modules/core/containers/LiveUpdatesContainer/LiveUpdatesContainer";
import { PersistGate } from "redux-persist/integration/react";
import { PlayerCoreContainer } from "./modules/core/containers/PlayerCoreContainer/PlayerCoreContainer";
import { LoggingContainer } from "./modules/core/containers/LoggingContainer/LoggingContainer";
import { PlayerState } from "./store/rootReducer";
import { Provider, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { ServiceWorkerManager } from "./serviceWorkerManager";
import { TimeSynchroniserContainer } from "./modules/core/containers/TimeSynchroniserContainer/TimeSynchroniserContainer";
import { connectToPMIForUpdates } from "./utils/connectToPMIForUpdates";
import { createPlayerStore } from "./store/createPlayerStore";
import { initAutoplayCheck } from "./utils/autoplay";
import { registerHttpInterceptor } from "./utils/httpInterceptor";
import { setConfig } from "./store/config/actions";
import { getStudioPlayerVersion } from "./utils/helpers";
import MenuProvider from "./providers/MenuProvider/MenuProvider";
import { FirestoreClient } from "@screencloud/signage-firestore-client";
import { isStorePersistDisabledFlag } from "./utils/devUtils";
import { Logger } from "./logger/logger";
import { StoreCleanupContainer } from "./modules/core/containers/StoreCleanupContainer/StoreCleanupContainer";
import HealthCheck from "./utils/healthCheck";
import { getInfoFromGraphqlToken, getScreenIdFromGraphqlToken } from "./utils";
import { ScheduleFilterCache } from "./utils/scheduleFilterCache";
import { Datacenter, datadogLogs } from "@screencloud/datadog-browser-logs";
import { initializeVideoPlayer } from "./videoPlayerManager";
import { cleanupQrCache } from "./utils/qrCode";
import studioPlayerExposedInterface from "./utils/pmi/exposedInterface";
import { FEATURE_FLAGS_ENUM } from "./featureFlags";
import { SecureMediaPolicyContainer } from "./modules/core/containers/SecureMediaPolicyContainer";
import { TimelinePlayback } from "./store/timelines/types";
import { CustomStorageEstimate, ExpirationData } from "./types";
import { SW_VIDEO_CACHE_ENABLED } from "./constants";

const log = new Logger("index");
const isTizenPlayer = navigator.userAgent.includes("ScreenCloud/Tizen");

function initDataDog() {
  const version = getStudioPlayerVersion();
  const service = "studio-player";
  datadogLogs.init({
    version,
    service,
    clientToken: process.env.REACT_APP_DATADOG_TOKEN ?? "",
    datacenter: Datacenter.US,
    env: process.env.REACT_APP_SC_ENV ?? "",
    forwardErrorsToLogs: true,
  });
  datadogLogs.logger.info("Initialized Datadog", { version, service });
}

function addDataDogContextFromJwtToken(
  screenId?: string,
  organizationId?: string
) {
  datadogLogs.logger.addContext("screenId", screenId);
  datadogLogs.logger.addContext("orgId", organizationId);
  datadogLogs.logger.info("Added DataDog Context", {
    screenId,
    organizationId,
  });
}

interface ContentProps {
  store: Store<PlayerState, AnyAction>;
  config: ConfigState;
  client: GraphQLClient;
  firestoreClient: FirestoreClient | undefined;
}

export function createFirestoreClient(
  orgId: string,
  region: string
): FirestoreClient | undefined {
  if (!orgId) {
    return undefined;
  }
  return new FirestoreClient({
    firebase: {
      apiKey: process.env[
        `REACT_APP_FIREBASE_API_KEY_${region.toUpperCase()}`
      ] as string,
      authDomain: process.env[
        `REACT_APP_FIREBASE_AUTH_DOMAIN_${region.toUpperCase()}`
      ] as string,
      projectId: process.env[
        `REACT_APP_FIREBASE_PROJECT_ID_${region.toUpperCase()}`
      ] as string,
    },
    organizationId: orgId,
    projectName: "liveUpdates",
  });
}

function initMediaHandlers(): Promise<[void, void] | void> {
  return initAutoplayCheck().catch((err) => {
    log.error({
      message: "Cannot initialize media handlers",
      context: { error: JSON.stringify(err) },
    });
  });
}

function initServiceWorker(
  isVideoCacheEnabled: boolean,
  role: string,
  device?: DeviceConfig
) {
  if (role !== "STUDIO_SCREEN") {
    log.info("Studio user detected, skipping service worker registration");
    return;
  }

  if (device?.model === "screen-preview") {
    log.info("Screen preview detected, skipping service worker registration");
    return;
  }

  if (process.env.NODE_ENV === "test") {
    log.info("Skipping service worker registration as the environment is test");
    return;
  }

  log.info("Registering service worker");
  ServiceWorkerManager.getInstance().register(isVideoCacheEnabled);
}

const Content: FunctionComponent<ContentProps> = ({
  store,
  config,
  client,
  firestoreClient,
}) => {
  const [hasInitialised, setHasInitialised] = useState(false);

  //Check screen data and features for key: sc_menuEnabled value: true
  //config.features?.menuEnabled is through embeddable menu
  const enableEmbedMenu = useSelector<PlayerState, boolean>(
    (state) =>
      String(state.screen.screenData?.sc_menuEnabled) === "true" ||
      config.features?.menuEnabled === true
  );

  const isPlayingChannel = useSelector<PlayerState, boolean>(
    (state) =>
      state.screen.activeContentItem?.type === "channel" ||
      config.contentConfig?.type === "channel"
  );

  const isPlayWithSystemTime = useSelector<PlayerState, boolean>((state) => {
    const isPlayWithSystemTime =
      state.screen.playbackMode ===
      TimelinePlayback.TIMELINE_PLAYBACK_DEVICE_SYSTEM_TIME;
    return isPlayWithSystemTime;
  });

  useEffect(() => {
    // 1. initial state is loaded, 2. persist gate loads cache, 3. sdk config is dispatched
    store.dispatch(setConfig(config));

    setHasInitialised(true);
  }, [store, config]);

  // Only display menu if enabled in screen data and a channel is set to screen
  const Wrapper = enableEmbedMenu && isPlayingChannel ? MenuProvider : Fragment;

  return hasInitialised ? (
    <ClientContext.Provider value={client}>
      <Wrapper>
        {!config.contentConfig.isPreview && (
          <LiveUpdatesContainer liveUpdateClient={firestoreClient} />
        )}
        <SecureMediaPolicyContainer
          mediaServiceEndpoint={config.secureMediaServiceUrl}
        >
          <StoreCleanupContainer />
          <TimeSynchroniserContainer
            timeServerUrl={
              process.env.REACT_APP_TIME_SERVER_URL ||
              "https://clock.screen.cloud"
            }
            isEnabled={!isPlayWithSystemTime}
          />
          <PlayerCoreContainer />
          <LoggingContainer />
        </SecureMediaPolicyContainer>
      </Wrapper>
    </ClientContext.Provider>
  ) : (
    <></>
  );
};

declare global {
  interface Window {
    studioplayer: {
      version?: string;
    };
    __sc_clearSWCache: () => Promise<boolean | string>;
    __sc_getSWStorage: () => Promise<CustomStorageEstimate>;
    __sc_setMediaCacheURL: (url: string) => Promise<{ success: boolean }>;
    __sc_getSWAllCachedDataByName: (name: string) => Promise<ExpirationData[]>;
    __sc_setSWMediaCacheUrl: (url: string) => Promise<boolean>;
    __sc_unregisterSW: () => Promise<void>;
    __sc_test_deleteOldEntries: (sizeNeeded: number) => void;
  }
}

// Pass metadata
window.studioplayer = {
  version: getStudioPlayerVersion(),
};

window.__sc_clearSWCache = async () => {
  const clearCacheRes = await ServiceWorkerManager.getInstance().clearServiceWorkerCache();
  return clearCacheRes;
};

window.__sc_getSWStorage = async () => {
  const storage = await ServiceWorkerManager.getInstance().getServiceWorkerStorage();
  return storage;
};

window.__sc_getSWAllCachedDataByName = async (name: string) => {
  const {
    allData,
  } = await ServiceWorkerManager.getInstance().getServiceWorkerAllCachedDataByName(
    name
  );
  return allData;
};

window.__sc_setSWMediaCacheUrl = async (url: string) => {
  const resp = await ServiceWorkerManager.getInstance().setServiceWorkerMediaCacheURL(
    url
  );
  return resp;
};

window.__sc_unregisterSW = async () => {
  await ServiceWorkerManager.getInstance().unregister();
};

if (process.env.NODE_ENV === "development") {
  window.__sc_test_deleteOldEntries = (sizeNeeded: number) => {
    if (navigator.serviceWorker.controller) {
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event) => {
        console.log("Response from service worker:", event.data);
      };

      navigator.serviceWorker.controller.postMessage(
        {
          type: "testDeleteOldEntries",
          sizeNeeded,
        },
        [messageChannel.port2]
      );
    }
  };
}

studioPlayerExposedInterface.expose();

async function initShakaPlayer() {
  datadogLogs.logger.info("Initializing VideoPlayer");
  await initializeVideoPlayer();
}

initDataDog();
initShakaPlayer();
log.info({
  message: "Application start",
  context: { name: "Application start" },
});
ConfigurationManager.getInstance()
  .getConfiguration()
  .then(async (config) => {
    if (config) {
      cleanupQrCache();
      datadogLogs.logger.info("Parsing GQL token");
      const { screenId, orgId, role } = getInfoFromGraphqlToken(
        config.graphqlToken
      );
      addDataDogContextFromJwtToken(screenId, orgId);

      // Offline storage isn't configurable on devices yet (devices not using sdk)
      // So default to true if the offline storage feature flag isn't set.
      const offlineStorage = config.features?.offlineStorage;
      const isOfflineStorageEnabled =
        (offlineStorage === false ? false : true) &&
        !isStorePersistDisabledFlag();

      datadogLogs.logger.info("Initializing player store");
      const { store, persistor } = createPlayerStore(
        isOfflineStorageEnabled,
        getScreenIdFromGraphqlToken(config.graphqlToken)
      );

      const client = createGraphQLClient(config);

      datadogLogs.logger.info("Initializing file processing client");
      const fileProcessingClient = createFileProcessingClient(config);
      const remoteApi = ConfigurationManager.getInstance().getRemoteApi();

      datadogLogs.logger.info("Connecting PMI");
      connectToPMIForUpdates(store);
      registerHttpInterceptor();

      datadogLogs.logger.info("Initializing media handlers");
      // Initialize video handling logic before any rendering begins.
      await initMediaHandlers();

      datadogLogs.logger.info("Initializing schedule filter cache");
      ScheduleFilterCache.init({
        maxMemory: 1000000,
      });

      datadogLogs.logger.info("Rendering DOM");

      const firestoreClient = createFirestoreClient(orgId, config.region);
      const playbackConfig = { isTizenPlayer };
      const isVideoCacheEnabled =
        store
          .getState()
          .organization.featureFlags?.includes(
            FEATURE_FLAGS_ENUM.SW_VIDEO_CACHE_ENABLED
          ) || localStorage.getItem(SW_VIDEO_CACHE_ENABLED) === "true";

      initServiceWorker(isVideoCacheEnabled, role, config.device);

      const contentElement = (
        <Content
          store={store}
          client={client}
          config={config}
          firestoreClient={firestoreClient}
        />
      );

      ReactDOM.render(
        <Provider store={store}>
          <ClientApiProvider
            value={{
              fileProcessingClient,
              remoteApi,
              playbackConfig,
            }}
          >
            {persistor ? (
              <PersistGate persistor={persistor} loading={null}>
                {contentElement}
              </PersistGate>
            ) : (
              contentElement
            )}
          </ClientApiProvider>
        </Provider>,
        document.getElementById("root")
      );

      datadogLogs.logger.info("Initializing HealthCheck");
      HealthCheck.getInstance().setRemoteApi(
        ConfigurationManager.getInstance().getRemoteApi().remote
      ); // reuse remote from connection in ConfigurationManager
      HealthCheck.getInstance().start();
    } else {
      // Could not find valid config, cannot start the player.
      // TODO - Make a friendlier message. Users *should* never see this however.
      ReactDOM.render(
        <p>
          We could not find valid config on your device and are unable to start
          the player. Please contact support for advice on how to reset this
          screen.
        </p>,
        document.getElementById("root")
      );
    }
  });
