const graphqlData = {
  data: {
    currentScreen: {
      id: "998b3dfc-61b2-4dee-8adf-80de47adc6c2",
      content: {
        _ref: { id: "1872cc20-317b-4b74-8077-af3e1296e05f", type: "channel" },
      },
      status: "LIVE",
      preview: false,
      device: {
        id: "5e3cf8b0-fd34-419f-8e53-07de70268b02",
        screenId: "998b3dfc-61b2-4dee-8adf-80de47adc6c2",
        settings: {
          name: "wc-l",
          updatedAt: 1589169208063,
          orientation: "landscape",
        },
        connection: "online",
        deviceModel: null,
        connected_at: 1589774532.245974,
        devicePlatform: "browser",
        disconnected_at: 1589773679.139381,
      },
      orgByOrgId: {
        id: "97874122-baa8-40f6-af6d-bbb9a4ead733",
        brandChannelId: "7c4d9e70-5162-48ee-ac6d-17ade0da307e",
        themeByDefaultChannelThemeId: null,
      },
      channelsByScreenId: {
        nodes: [
          {
            id: "1872cc20-317b-4b74-8077-af3e1296e05f",
            content: {
              props: {
                default_durations: {
                  app: 60000,
                  link: 60000,
                  image: 10000,
                  document: 10000,
                },
              },
              zones: {
                zone1: {
                  list: [
                    {
                      rules: [],
                      content: {
                        _ref: {
                          id: "052bfa7a-0334-49e2-a7c7-92af7e3d538e",
                          type: "file",
                        },
                        props: {},
                      },
                      list_id: "09244c8f-17d5-41a0-b4f4-ef09d486d742",
                    },
                  ],
                  props: {
                    sizing_type: {
                      image: "fill",
                      video: "fill",
                      document: "fill",
                    },
                  },
                },
              },
            },
            width: 1920,
            height: 1080,
            layoutByChannel: {
              id: "80db9beb-0ca3-4884-a8b4-19fa12a144d4",
              zones: 1,
              config: {
                css:
                  "<style>#zone1{position:absolute;width:100%;height:100%;top:0;left:0;z-index:10;}</style>",
                name: "Main",
                width: 1920,
                zones: [{ id: "zone1", name: "Main" }],
                height: 1080,
                is_flexible: true,
                is_scalable: true,
              },
              width: 1920,
              height: 1080,
              isFlexible: true,
              isScalable: true,
            },
            appInstancesByChannelId: { nodes: [] },
            playlistsByChannelId: { nodes: [] },
            filesByChannelId: {
              nodes: [
                {
                  id: "052bfa7a-0334-49e2-a7c7-92af7e3d538e",
                  orgId: "97874122-baa8-40f6-af6d-bbb9a4ead733",
                  name: "IMG_20191229_203549.jpg",
                  availableAt: null,
                  expireAt: null,
                  fileProcessingStatus: "COMPLETED",
                  mimetype: "image/jpeg",
                  source:
                    "https://secure-media.staging.eu.next.sc/97874122-baa8-40f6-af6d-bbb9a4ead733/originals/teM8oTCAR5iLeeI2S1AC_IMG_20191229_203549_jpg",
                  metadata: {
                    key: "teM8oTCAR5iLeeI2S1AC_IMG_20191229_203549_jpg",
                    url:
                      "https://cdn.filestackcontent.com/fQ0XojlTYuG41P4Z1iEP",
                    size: 1307373,
                    width: 4608,
                    handle: "fQ0XojlTYuG41P4Z1iEP",
                    height: 2304,
                    source: "local_file_system",
                    status: "Stored",
                    filename: "IMG_20191229_203549.jpg",
                    mimetype: "image/jpeg",
                    uploadId: "3XOIVUbY6kZbEJxM",
                    container: "studio-filestack-staging-eu-west-1",
                    originalFile: {
                      name: "IMG_20191229_203549.jpg",
                      size: 1307373,
                      type: "image/jpeg",
                    },
                    originalPath: "IMG_20191229_203549.jpg",
                  },
                  fileOutputsByFileId: { nodes: [] },
                },
              ],
            },
            linksByChannelId: { nodes: [] },
            themeByThemeId: null,
            orgByOrgId: {
              id: "97874122-baa8-40f6-af6d-bbb9a4ead733",
              themeByDefaultChannelThemeId: null,
            },
          },
        ],
      },
      appInstancesByScreenId: { nodes: [] },
      filesByScreenId: { nodes: [] },
      linksByScreenId: { nodes: [] },
      playlistsByScreenId: { nodes: [] },
      castByCastId: null,
    },
  },
  meta: { graphqlQueryCost: 169793853 },
};

export { graphqlData };
