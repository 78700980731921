import React, { FunctionComponent } from "react";
import styles from "./LandingMenu.module.css";
import Logo from "../../../img/logo-default.svg";
import { useEmbedMenu } from "../../../providers/MenuProvider/MenuProvider";
import PlayIcon from "../../../img/play-icon.svg";
import { useSecureThemeLogo } from "../../../utils/useSecureThemeLogo";

interface Props {
  onInitialise: () => void;
  channelName?: string;
  channelId?: string;
}

const LandingMenu: FunctionComponent<Props> = ({
  onInitialise,
  channelName,
}: Props) => {
  const channel =
    channelName && channelName.length > 0 ? channelName : "ScreenCloud";

  const { theme } = useEmbedMenu();
  const secureLogo = useSecureThemeLogo(theme);

  return (
    <div
      data-testid="landing-menu"
      className={styles.root}
      style={theme && { borderLeft: "16px solid " + theme?.primaryColor[500] }}
    >
      <div className={styles.content}>
        <img
          src={theme ? secureLogo : Logo}
          className={styles.logo}
          alt="logo"
        />
        <h1
          data-testid="landing-menu-header"
          style={{ fontFamily: theme?.headingFont?.family }}
        >
          <mark
            style={{
              backgroundColor: theme?.primaryColor[500],
              color: theme?.textOnPrimary[500],
            }}
          >
            {channel}
          </mark>
        </h1>

        <img
          data-testid="initialise-button"
          onClick={onInitialise}
          src={PlayIcon}
          alt="Play"
          className={styles.playIcon}
        />
      </div>
    </div>
  );
};
export default LandingMenu;
