import { useSelector } from "react-redux";
import { PlayerState } from "../store/rootReducer";
import { ConfigState } from "../store/config/types";
import { replaceWithSecureURL } from "./FileProcessingClientWrapped";
import { ignoreToCacheTheseParamsOnOurNativePlayers } from "./helpers";
import { extractOrgIdFromUrl } from "../modules/viewers/containers/AppViewerContainer/useAppInstanceFiles";
import { Theme } from "../store/themes/types";

/**
 * Hook that transforms a theme's logo URL to a secure media URL when appropriate.
 * Similar to the pattern used in useOverrideAppInitialize and useAppInstanceFiles.
 * @param theme - The theme object containing the logo URL
 * @returns The processed logo URL with secure media transformation if applicable
 */
export function useSecureThemeLogo(theme?: Theme): string | undefined {
  const config = useSelector<PlayerState, ConfigState>((state) => state.config);

  const orgId = useSelector<PlayerState, string>(
    (state) => state.screen.orgId || state.organization?.id
  );

  const secureMediaServiceURL = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaServiceUrl
  );

  const secureMediaPolicy = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaPolicy
  );

  if (!theme?.logo) {
    return undefined;
  }

  if (secureMediaServiceURL) {
    const extractedOrgId = extractOrgIdFromUrl(theme.logo);
    if (extractedOrgId === orgId) {
      return `${replaceWithSecureURL(
        config,
        theme.logo,
        secureMediaServiceURL
      )}${ignoreToCacheTheseParamsOnOurNativePlayers(secureMediaPolicy)}`;
    }
  }

  return theme.logo;
}
