import { App } from "../../../../store/apps/types";
import {
  ConnectedMessage,
  GenericMessage,
  InitializeMessage,
  MediaProxyConfig,
  StartMessage,
  TokenUpdatedMessage,
  TokenUpdatedPayload,
} from "./types";
import { Theme } from "../../../../store/themes/types";
import {
  ConfigState,
  ContextConfig,
  DeviceConfig,
} from "../../../../store/config/types";
import { PlayerFile } from "../../../../store/files/types";
import { Logger } from "../../../../logger/logger";
import isEmpty from "lodash/isEmpty";

export const makeConnectedMessage = (): ConnectedMessage => {
  return { type: "CONNECT_SUCCESS" };
};

export const makeStartMessage = (): StartMessage => {
  return { type: "start" };
};

export const makeTokenUpdatedMessage = (
  payload: TokenUpdatedPayload
): TokenUpdatedMessage => {
  return { type: "tokenUpdated", payload };
};

export const makeInitializeMessage = (
  app: App,
  region: string,
  filesByAppInstanceId: { nodes: Array<PlayerFile> },
  theme?: Theme,
  orgId = "",
  screenId = "",
  spaceId = "",
  screenData?: { [key: string]: string },
  durationMs?: number,
  contextConfig: ContextConfig = {},
  device?: DeviceConfig | undefined,
  durationElapsedMs?: number,
  appViewerToken?: string,
  additionalQrKey?: string,
  isScreenMuted?: boolean,
  playerWidth?: number,
  playerHeight?: number,
  playerTimezone?: string,
  timezoneOverride?: string,
  config?: ConfigState
): InitializeMessage => {
  return {
    type: "initialize",
    payload: {
      spaceId,
      appInstanceId: app.id,
      config: app.config,
      orgId,
      screenId,
      context: {
        ...contextConfig,
        theme,
        screenData,
        loggingLevel: Logger.currentLevel,
        playerWidth,
        playerHeight,
        timezone: timezoneOverride || playerTimezone,
        region,
        appViewerToken,
        session: {
          id: additionalQrKey,
        },
        mute: isScreenMuted || false,
        mediaProxyConfig: getMediaProxyConfig(config),
      },
      device,
      filesByAppInstanceId,
      durationMs,
      durationElapsedMs,
    },
  };
};

// TODO - postMessage natively serializes elements. Why do we JSON.stringify everything?
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
export const sendMessage = (
  frame: HTMLIFrameElement,
  message: GenericMessage,
  targetOrigin: string,
  requestId?: number
): void => {
  const { type } = message;

  // Only CONNECT, CONNECT_SUCCESS, DISCONNECT messages add this ___ thing.
  // The rest nest their data under a 2nd "data" key.
  // `requestAuthToken` uses a specific format with a referenceId and requestId
  let processedMessage;

  if (["CONNECT", "CONNECT_SUCCESS", "DISCONNECT"].includes(type)) {
    processedMessage = `___${JSON.stringify(message)}`;
  } else if (type === "requestAuthToken") {
    processedMessage = JSON.stringify({
      data: message.payload,
      referenceId: requestId,
      requestId,
    });
  } else {
    processedMessage = JSON.stringify({
      data: message,
    });
  }

  frame.contentWindow?.postMessage(processedMessage, targetOrigin);
};

export const isFontFileURL = (url: string | undefined): boolean => {
  if (url && url.indexOf("fonts.googleapis") > -1) {
    return false;
  } else if (url && url.indexOf(".css") > -1) {
    return false;
  } else {
    return true;
  }
};

export const getMediaProxyConfig = (
  config?: ConfigState
): MediaProxyConfig | undefined => {
  if (
    !config?.mediaProxyEndpoint &&
    !config?.mediacacheBaseUrl &&
    isEmpty(config?.mediacacheBaseUrl)
  ) {
    return undefined;
  }

  return {
    mediaProxyEndpoint: config?.mediaProxyEndpoint,
    mediacacheBaseUrl: config?.mediacacheBaseUrl,
  };
};
