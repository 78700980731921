import { useDispatch, useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { useEffect, useMemo, useState } from "react";
import { ConfigState } from "../../../../store/config/types";
import { replaceWithSecureURL } from "../../../../utils/FileProcessingClientWrapped";
import { ignoreToCacheTheseParamsOnOurNativePlayers } from "../../../../utils/helpers";
import { InitializeMessagePayload } from "../AppViewer/types";
import { requestOrgSuccess } from "../../../../store/organization/actions";
import { useOrgById } from "../../../../queries";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import { extractOrgIdFromUrl } from "./useAppInstanceFiles";

/**
 * Hook that provides override initialization data for app preview mode.
 * Returns initialization payload if in preview mode and content path matches,
 * otherwise returns undefined.
 */
export const useOverrideAppInitialize = (
  isPreview: boolean
): Partial<InitializeMessagePayload> | undefined => {
  const dispatch = useDispatch();

  const contentPathId = useSelector<PlayerState, string | undefined>(
    (state) => state.config.contentConfig.id
  );

  const overrideAppInitialize = useSelector<
    PlayerState,
    Partial<InitializeMessagePayload> | undefined
  >((state) => {
    if (isPreview) {
      const overrideAppInitialize = state.config.overrideAppInitialize;
      // Ensure content path matches overrideAppInitialize being sent
      if (overrideAppInitialize?.appInstanceId === contentPathId) {
        return overrideAppInitialize;
      }
    }
    return undefined;
  });

  // Load org details from overrideAppInitialize
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [fetchOrg, { data: orgData }] = useOrgById({
    useCache: false,
    skipCache: true,
    variables: {
      id: overrideAppInitialize?.orgId,
    },
  });
  useEffect(() => {
    if (orgData?.orgById) {
      dispatch(requestOrgSuccess(orgData.orgById));
      setLoaded(true);
    }
  }, [orgData?.orgById, dispatch]);
  useInitialFetch(!!overrideAppInitialize?.orgId && !isLoaded, fetchOrg);

  // Load secure media config
  const config = useSelector<PlayerState, ConfigState>((state) => state.config);

  const secureMediaServiceURL = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaServiceUrl
  );
  const secureMediaPolicy = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaPolicy
  );

  const orgId = useSelector<PlayerState, string>(
    (state) => state.organization.id
  );

  const updatedOverrideAppInitialize = useMemo(() => {
    const originalNodes =
      overrideAppInitialize?.filesByAppInstanceId?.nodes || [];

    const updatedNodes = originalNodes.map((node) => {
      if (node.source && secureMediaServiceURL) {
        const extractedOrgId = extractOrgIdFromUrl(node.source);
        if (extractedOrgId === orgId) {
          const newSecureURLWithPolicy = `${replaceWithSecureURL(
            config,
            node.source || "",
            secureMediaServiceURL
          )}${ignoreToCacheTheseParamsOnOurNativePlayers(secureMediaPolicy)}`;
          return { ...node, source: newSecureURLWithPolicy };
        }
      }
      return node;
    });

    if (
      overrideAppInitialize &&
      overrideAppInitialize.filesByAppInstanceId &&
      updatedNodes
    ) {
      overrideAppInitialize.filesByAppInstanceId.nodes = updatedNodes;
    }
    return overrideAppInitialize;
  }, [
    overrideAppInitialize,
    config,
    orgId,
    secureMediaPolicy,
    secureMediaServiceURL,
  ]);

  return updatedOverrideAppInitialize;
};
